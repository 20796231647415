import { useSession } from "components/providers/session";
import Link from "next/link";
import { currency } from "utils/formats";

type OnlineCourseProps = {
  onlineCourse: Entry;
  itemIndex: number;
  hsData?: any;
};

export default function OnlineCourse({
  onlineCourse,
  itemIndex,
}: OnlineCourseProps) {
  const { removeOnlineCourses, setContext } = useSession();

  const pricingOption = onlineCourse.fields.pricing;

  return (
    <>
      <div className="py-4 flex space-x-6">
        {onlineCourse.fields.listingImage.fields.file.url && (
          <div className="relative flex-none w-16 h-16 md:w-24 md:h-24 bg-gray-100 rounded-md">
            <img
              alt={onlineCourse.fields.listingImage.fields.title}
              src={onlineCourse.fields.listingImage.fields.file.url}
              className="w-full object-cover h-full bg-gray-100 rounded-md"
            />
          </div>
        )}
        <div className="flex-auto flex flex-col">
          <div className="sm:space-y-1 flex items-start sm:justify-between sm:space-x-6 flex-1">
            <div className="flex-auto text-sm font-medium space-y-1">
              <h3 className="text-gray-900">
                <Link
                  href={onlineCourse.fields.url}
                  passHref
                  className="focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2 rounded"
                >
                  {onlineCourse.fields.title}
                </Link>
              </h3>

              {/* {discount && (
                <>
                  {service && onlineCourse.canBeDiscounted ? (
                    <>
                      {onlineCourse.discountValue ? (
                        <p className="text-gray-900">
                          Discount Value: -{' '}
                          <span className="text-gray-600">
                            {currency(onlineCourse.discountValue)}
                          </span>
                        </p>
                      ) : (
                        <p className="text-gray-900">
                          Item Eligible For Discount
                        </p>
                      )}
                    </>
                  ) : (
                    <p className="text-red-600">
                      Item Not Eligible For Discount
                    </p>
                  )}
                </>
              )} */}

              <div className="flex justify-between">
                {pricingOption && (
                  <p className="text-brand-600 block">
                    {currency(pricingOption.unit_amount)}
                  </p>
                )}

                {onlineCourse.fields.data.was ? (
                  <p className="text-sm text-gray-500 block">
                    Was: {currency(onlineCourse.fields.data.was)}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="w-full flex items-center justify-between">
            <Link
              href={onlineCourse.fields.url}
              type="button"
              onClick={() => {
                setContext((s) => ({ ...s, onlineBasket: false }));
              }}
              className="relative font-medium flex items-center justify-center py-1 px-2.5 border border-gray-300 text-xs rounded-md text-gray-800 bg-white shadow-sm transition-all duration-200 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2 focus:border-gray-300 focus:bg-gray-200 focus:text-gray-900"
            >
              View Course Details
            </Link>

            <button
              onClick={() => removeOnlineCourses(onlineCourse.fields.url)}
              className="relative font-medium flex items-center justify-center py-1 px-2.5 border border-gray-300 text-xs rounded-md text-gray-800 bg-white shadow-sm transition-all duration-200 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2 focus:border-gray-300 focus:bg-gray-200 focus:text-gray-900"
            >
              <span className="text-red-600">Remove</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

// TypeScript types for the provided JSON structure

export interface Metadata {
  tags: any[];
  concepts: any[];
}

export interface Sys {
  space: Link;
  type: string;
  id: string;
  contentType: Link;
  revision: number;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  firstPublishedAt: string;
  publishedVersion: number;
  environment: Link;
  locale: string;
}

export interface Link {
  sys: {
    type: string;
    linkType: string;
    id: string;
  };
}

export interface File {
  url: string;
  details: {
    size: number;
    image?: {
      width: number;
      height: number;
    };
  };
  fileName: string;
  contentType: string;
}

export interface ListingImage {
  metadata: Metadata;
  sys: Sys;
  fields: {
    title: string;
    description: string;
    file: File;
  };
}

export interface RichText {
  nodeType: string;
  data: Record<string, unknown>;
  content: Array<{
    nodeType: string;
    data: Record<string, unknown>;
    content: Array<{
      nodeType: string;
      value: string;
      marks: any[];
      data: Record<string, unknown>;
    }>;
  }>;
}

export interface Content {
  metadata: Metadata;
  sys: Sys;
  fields: {
    title: string;
    subtitle?: string;
    body?: RichText;
    images?: ListingImage[];
    description?: RichText;
    cpdAccredited?: boolean;
    vtctAccredited?: boolean;
    requiresExamination?: boolean;
  };
}

export interface Pricing {
  id: string;
  object: string;
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: string;
  custom_unit_amount: any;
  livemode: boolean;
  lookup_key: any;
  metadata: Record<string, unknown>;
  nickname: any;
  product: string;
  recurring: any;
  tax_behavior: string;
  tiers_mode: any;
  transform_quantity: any;
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
}

export interface PaymentLink {
  id: string;
  object: string;
  active: boolean;
  after_completion: {
    redirect: {
      url: string;
    };
    type: string;
  };
  allow_promotion_codes: boolean;
  application: any;
  application_fee_amount: any;
  application_fee_percent: any;
  automatic_tax: {
    enabled: boolean;
    liability: any;
  };
  billing_address_collection: string;
  consent_collection: {
    payment_method_reuse_agreement: any;
    promotions: string;
    terms_of_service: string;
  };
  currency: string;
  custom_fields: any[];
  custom_text: {
    after_submit: any;
    shipping_address: any;
    submit: any;
    terms_of_service_acceptance: any;
  };
  customer_creation: string;
  inactive_message: any;
  invoice_creation: {
    enabled: boolean;
    invoice_data: {
      account_tax_ids: any;
      custom_fields: any;
      description: any;
      footer: any;
      issuer: any;
      metadata: Record<string, unknown>;
      rendering_options: any;
    };
  };
  line_items: {
    object: string;
    data: Array<{
      id: string;
      object: string;
      amount_discount: number;
      amount_subtotal: number;
      amount_tax: number;
      amount_total: number;
      currency: string;
      description: string;
      price: Pricing;
      quantity: number;
    }>;
    has_more: boolean;
    url: string;
  };
  livemode: boolean;
  metadata: Record<string, unknown>;
  on_behalf_of: any;
  payment_intent_data: any;
  payment_method_collection: string;
  payment_method_types: any;
  phone_number_collection: {
    enabled: boolean;
  };
  restrictions: any;
  shipping_address_collection: any;
  shipping_options: any[];
  submit_type: string;
  subscription_data: any;
  tax_id_collection: {
    enabled: boolean;
    required: string;
  };
  transfer_data: any;
  url: string;
}

export interface Data {
  pre: string;
  was: string;
  full: string;
  active: boolean;
  preLink: string;
  release: string;
  fullLink: string;
  paymentLinkId: string;
  productId: string;
  priceId: string;
}

export interface Fields {
  title: string;
  type: string;
  package: boolean;
  categories: string[];
  url: string;
  listingText: RichText;
  listingImage: ListingImage;
  metadata: Metadata;
  content: Content[];
  data: Data;
  link: PaymentLink;
  pricing: Pricing;
}

export interface Entry {
  metadata: Metadata;
  sys: Sys;
  fields: Fields;
}
