import { useState, Fragment } from "react";
import Link from "next/link";
import Image from "next/image";

// import { useRouter } from "next/router";
// import { useOnClickOutside } from "utils/hooks/click-outside";
import { useSession } from "./providers/session";
import { Popover, Dialog, Tab } from "@headlessui/react";
import {
  Bars3Icon,
  UserIcon,
  XMarkIcon,
  ShoppingBagIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import dynamic from "next/dynamic";
import OnlineBookingCheckout from "./checkout/panel";

type NavigationItem = {
  name: string;
  href: string;
};

type FeaturedItem = {
  name: string;
  href: string;
  imageSrc: string;
  imageAlt: string;
};

type SectionItem = {
  name: string;
  href: string;
};

type Section = {
  id: string;
  name: string;
  link: string;
  items: SectionItem[];
};

type Category = {
  id: string;
  link?: string;
  name: string;
  featured: FeaturedItem[];
  sections: Section[][];
};

type Page = {
  name: string;
  href: string;
};

type MoreSectionItem = {
  name: string;
  href: string;
};

type MoreSection = {
  id: string;
  name: string;
  link: string;
  items: MoreSectionItem[];
};

type MoreCategory = {
  id: string;
  name: string;
  featured: FeaturedItem[];
  sections: MoreSection[][];
};

type MobileMenuItem = {
  name: string;
  href: string;
};

type Navigation = {
  categories: Category[];
  pages: Page[];
  more: MoreCategory;
  mobile: MobileMenuItem[];
};

const navigation: Navigation = {
  categories: [
    {
      id: "courses",
      name: "Training Courses",
      link: "/courses/",
      featured: [
        {
          name: "VTCT Level 4 Laser Hair Removal",
          href: "/courses/vtct/laser-hair-removal/",
          imageSrc:
            "https://images.ctfassets.net/eo68gvto3q0j/4Fd21vgbsimRH63rXx4ctc/419f5e9dd6852d08cc675a10f9674a84/shutterstock_435284326.jpg",
          imageAlt:
            "Get qualified in laser hair removal with our VTCT Level 4 course in 4 days",
        },
        {
          name: "Level 5 Tattoo Removal",
          href: "/courses/vtct/level-5-certificate-in-laser-tattoo-removal/",
          imageSrc:
            "https://images.ctfassets.net/eo68gvto3q0j/7D20Uc0mfHyhTVo3tGIvdA/88702142276528f9d60019ae04fca183/laser-tattoo-removal-cosmetic-college.jpg",
          imageAlt:
            "An intensive, practical 5-day programme designed to equip you with advanced knowledge and skills in laser technology for the effective removal of unwanted tattoos.",
        },
        {
          name: "VTCT Level 4 Advanced Aesthetics",
          href: "/courses/vtct/level-4-diploma-in-advanced-aesthetic-treatments/",
          imageSrc:
            "https://images.ctfassets.net/eo68gvto3q0j/4sQUUdOQljK83lkQccsB2Z/9c3779569f74209e8539c5ae4bb7b688/dermaplaning_pic_.jpg",
          imageAlt:
            "Elevate your professional credentials with our VTCT (iTEC) Level 4 Diploma in Advanced Aesthetic Treatments, specialising in Skin Needling, Dermaplaning and Skin Peeling. ",
        },
      ],
      sections: [
        [
          {
            id: "vtct",
            name: "VTCT",
            link: "/courses/vtct/",
            items: [
              {
                name: "Level 3 Access To Aesthetics",
                href: "/courses/vtct/level-3-certificate-in-access-to-aesthetic-therapies/",
              },
              {
                name: "Laser Hair Removal",
                href: "/courses/vtct/laser-hair-removal/",
              },
              {
                name: "Level 6 Mesotherapy",
                href: "/courses/vtct/level-6-award-in-mesotherapy-treatments/",
              },
              {
                name: "Micropigmentation",
                href: "/courses/vtct/level-4-certificate-in-micropigmentation/",
              },
              {
                name: "Microblading",
                href: "/courses/vtct/level-4-certificate-in-enhancing-eyebrows-with-microblading-techniques/",
              },
              { name: "View more", href: "/courses/vtct/" },
            ],
          },
          {
            id: "online",
            name: "Online Courses",
            link: "/courses/elearning/",
            items: [
              {
                name: "Microneedling",
                href: "/courses/elearning/microneedling/",
              },
              {
                name: "Vitamin Injections",
                href: "/courses/elearning/online-vitamin-b12-injection/",
              },
              {
                name: "Anatomy & Physiology",
                href: "/courses/elearning/vtct-level-3-award-in-anatomical-and-physiological-knowledge-of-body/",
              },
              {
                name: "Dermaplaning",
                href: "/courses/elearning/dermaplaning/",
              },
              {
                name: "Bio Stimulators",
                href: "/courses/elearning/online-bio-stimulators-sculptra-and-aesplla-training/",
              },
              {
                name: "View more",
                href: "/courses/elearning/",
              },
            ],
          },
        ],
        [
          {
            id: "aesthetics",
            name: "Aesthetics",
            link: "/courses/aesthetics/",
            items: [
              {
                name: "Anti Wrinkle Injections",
                href: "https://www.cosmetic.college/courses/aesthetics/foundation-anti-wrinkle-injections-course/",
              },
              {
                name: "Dermal Fillers",
                href: "https://www.cosmetic.college/courses/aesthetics/foundation-dermal-fillers/",
              },
              {
                name: "Skin Boosters",
                href: "https://www.cosmetic.college/courses/aesthetics/skin-boosters/",
              },
              {
                name: "Polynucleotides",
                href: "https://www.cosmetic.college/courses/aesthetics/polynucleotides-skin-rejuvenation/",
              },
              {
                name: "Fat Dissolving",
                href: "https://www.cosmetic.college/courses/aesthetics/aqualyx-fat-dissolving/",
              },
              { name: "View more", href: "/courses/aesthetics/" },
            ],
          },
          {
            id: "cpd",
            name: "CPD",
            link: "/courses/cpd/",
            items: [
              {
                name: "Dermaplaning",
                href: "https://www.cosmetic.college/courses/beauty/dermaplaning/",
              },
              {
                name: "Lymphatic Drainage",
                href: "https://www.cosmetic.college/courses/beauty/lymphatic-drainage-massage/",
              },
              {
                name: "Plasma Fibroblast",
                href: "https://www.cosmetic.college/courses/aesthetics/plasma-fibroblast-skin-tightening/",
              },
              {
                name: "Chemical Skin Peels",
                href: "https://www.cosmetic.college/courses/aesthetics/chemical-skin-peels/",
              },
              {
                name: "Sclerotherapy",
                href: "https://www.cosmetic.college/courses/aesthetics/sclerotherapy/",
              },
              { name: "View more", href: "/courses/cpd/" },
            ],
          },
        ],
        [
          {
            id: "packages",
            name: "Training Packages",
            link: "/courses/packages/",
            items: [
              {
                name: "VTCT Aesthetics Fast Track",
                href: "/courses/packages/vtct-itec-aesthetics-fast-track-package/",
              },
              {
                name: "PDO Threads Package",
                href: "/courses/packages/pdo-threads-training-package/",
              },
              {
                name: "Dermal Filler Package",
                href: "/courses/packages/advanced-dermal-filler-training-package/",
              },
              {
                name: "Skin Booster & Fat Dissolving Package",
                href: "/courses/packages/lemon-bottle-skin-booster-and-fat-dissolving-training-package/",
              },
              {
                name: "View more",
                href: "/courses/packages/",
              },
            ],
          },
          {
            id: "education",
            name: "Education",
            link: "/courses/education/",
            items: [
              {
                name: "Level 3 Education",
                href: "/courses/vtct/vtct-level-3-award-in-education-and-training/",
              },
              {
                name: "Level 3 Assessors",
                href: "/courses/vtct/vtct-level-3-certificate-in-assessing-vocational-achievement/",
              },
              {
                name: "Level 4 IQA",
                href: "/courses/vtct/level-4-award-in-the-internal-quality-assurance-of-assessment-processes/",
              },
              {
                name: "Level 5 Education",
                href: "/courses/education/",
              },
              { name: "View more", href: "/courses/education/" },
            ],
          },
        ],
      ],
    },
  ],
  pages: [
    {
      name: "Online Courses",
      href: "/courses/elearning/",
    },
    { name: "Models", href: "/models/" },
  ],
  more: {
    id: "company",
    name: "More",
    featured: [
      {
        name: "Brand New Training Courses",
        href: "/courses/new/",
        imageSrc:
          "https://images.ctfassets.net/eo68gvto3q0j/5IvYAO9m7Jz5jvfpjLu1tC/c7053271c0942fa9885afb79942c123f/IMG_1836.JPG",
        imageAlt: "Brand New Training Courses",
      },
      {
        name: "Elevate Your Expertise: Why Upgrade to Level 4 in Microneedling",
        href: "/blog/elevate-your-expertise-why-upgrade-to-level-4-in-microneedling/",
        imageSrc:
          "https://images.ctfassets.net/eo68gvto3q0j/5Solb4dKgnGpLgG4E2B9J6/7002e8953250dcd098e7f183706f9adc/Why_Upgrade_to_Level_4_in_Microneedling.png",
        imageAlt:
          "Elevate Your Expertise: Why Upgrade to Level 4 in Microneedling",
      },
      {
        name: "Understanding CPD and OfQual Accreditations: What They Mean and How They Benefit Students",
        href: "/blog/understanding-cpd-and-ofqual-accreditations/",
        imageSrc:
          "https://images.ctfassets.net/eo68gvto3q0j/idJDxHePEbt0nLgEzIKdF/c8fb24a0ee9acfa6608c7b52258ee2ba/Laser_training___Cosmetic_College.jpg",
        imageAlt:
          "Understanding CPD and OfQual Accreditations: What They Mean and How They Benefit Students",
      },
    ],
    sections: [
      [
        {
          id: "company",
          name: "Company",
          link: "",
          items: [
            {
              name: "Upcoming Courses",
              href: "/training-course-calendar/",
            },
            { name: "About Us", href: "/about/" },
            { name: "Meet The Team", href: "/team/" },
            { name: "Join The Team!", href: "/careers/" },
            { name: "Franchise Opportunities", href: "/franchise-with-us/" },
            { name: "Blog", href: "/blog/" },
            { name: "Contact Us", href: "/contact/" },
            { name: "Call Us", href: "tel:+443330155117" },
            { name: "Request A Callback", href: "/request-a-callback/" },
          ],
        },
      ],
      [
        {
          id: "content",
          link: "",
          name: "Exclusive Content",
          items: [
            { name: "Online Subscription ", href: "https://www.instagram.com/thecosmeticcollege/" },
            { name: "Blog", href: "/blog/" },
          ],
        },
        {
          id: "account",
          link: "/account/",
          name: "Accounts",
          items: [
            { name: "Sign in ", href: "/sign-in/" },
            { name: "Sign Up", href: "/sign-up/" },
          ],
        },
      ],
      [
        {
          id: "legal",
          name: "Legal",
          link: "",
          items: [
            { name: "Privacy Policy", href: "/privacy-policy/" },
            { name: "Terms & Conditions", href: "/terms-and-conditions/" },
            { name: "Payment Options", href: "/finance/" },
          ],
        },
        {
          id: "socials",
          name: "Social Media",
          link: "",
          items: [
            {
              name: "Facebook",
              href: "https://facebook.com/thecosmeticcollegeuk",
            },
            {
              name: "Instagram",
              href: "https://instagram.com/thecosmeticcollege",
            },
            {
              name: "Tik Tok",
              href: "https://www.tiktok.com/@cosmeticcollege",
            },
            { name: "YouTube", href: "https://youtube.com/@cosmeticcollege" },
          ],
        },
      ],
    ],
  },
  mobile: [
    {
      name: "Upcoming Courses",
      href: "/training-course-calendar/",
    },
    {
      name: "New Courses",
      href: "/courses/new/",
    },
    {
      name: "Blog",
      href: "/blog",
    },
    {
      name: "About Us",
      href: "/about",
    },
    {
      name: "Meet The Team",
      href: "/team",
    },
    {
      name: "For Businesses",
      href: "/for-business",
    },
    {
      name: "Franchise Opportunities",
      href: "/franchise-with-us",
    },
  ],
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const HeaderSearch = dynamic(() => import("./organisms/header-search"));
const message = "VAT FREE VTCT/CPD Courses Code: 2025 • Offer Ends Jan 30th";
const janMessage =
  "50% Discount off CPD Courses, Code: JAN50 • Offer Ends Jan 30th";

export default function Navigation({ siteData, ...rest }) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(false);
  const session = useSession();

  console.log("siteData", siteData);
  return (
    <>
      <div className="relative flex items-center justify-between gap-x-6 bg-brand-800 px-6 py-2.5 sm:pr-3.5 lg:pl-8">
        <div className="flex items-center gap-x-[30%] scrolling absolute inset-0 w-[200%] xl:w-[100%]">
          <p className="text-sm/6 text-white">
            <a href="/courses/vtct/">
              <strong className="font-semibold">£999!</strong>
              <svg
                viewBox="0 0 2 2"
                aria-hidden="true"
                className="mx-2 inline h-0.5 w-0.5 fill-current"
              >
                <circle r={1} cx={1} cy={1} />
              </svg>
              <strong className="font-semibold">
                Pick any 3 CPD courses with code CPD999{" "}
              </strong>
              <span aria-hidden="true">&rarr;</span>
            </a>
            <a href="/courses/vtct/level-4-diploma-in-advanced-aesthetic-treatments/">
              <strong className="font-semibold">Brand New!</strong>
              <svg
                viewBox="0 0 2 2"
                aria-hidden="true"
                className="mx-2 inline h-0.5 w-0.5 fill-current"
              >
                <circle r={1} cx={1} cy={1} />
              </svg>
              <strong className="font-semibold">
                VTCT Level 4 Advanced Aesthetics{" "}
              </strong>
              <span aria-hidden="true">&rarr;</span>
            </a>
            <a href="/courses/vtct/level-5-certificate-in-laser-tattoo-removal/">
              <svg
                viewBox="0 0 2 2"
                aria-hidden="true"
                className="mx-2 inline h-0.5 w-0.5 fill-current"
              >
                <circle r={1} cx={1} cy={1} />
              </svg>
              <strong className="font-semibold">
                VTCT Level 5 Laser Tattoo Removal{" "}
              </strong>
              <span aria-hidden="true">&rarr;</span>
            </a>
            <a href="/courses/vtct/level-6-award-in-mesotherapy-treatments/">
              <svg
                viewBox="0 0 2 2"
                aria-hidden="true"
                className="mx-2 inline h-0.5 w-0.5 fill-current"
              >
                <circle r={1} cx={1} cy={1} />
              </svg>
              <strong className="font-semibold">
                VTCT Level 6 Mesotherapy{" "}
              </strong>
              <span aria-hidden="true">&rarr;</span>
            </a>
          </p>
        </div>
        <button
          type="button"
          // onClick={() => setState({ ...state, banner: false })}
          className="-m-3 flex-none p-3 focus-visible:outline-offset-[-4px]"
        >
          <span className="sr-only">Dismiss</span>
          <XMarkIcon
            aria-hidden="true"
            className="h-5 w-5 text-white opacity-0"
          />
        </button>
      </div>
      <div className="bg-white relative z-50">
        {/* Mobile menu */}
        <Dialog
          open={open}
          onClose={setOpen}
          className="relative z-50 lg:hidden"
        >
          <Dialog.Backdrop className="fixed inset-0 bg-black/25 transition-opacity duration-300 ease-linear opacity-1 z-40" />

          <div className="fixed inset-0 z-40 flex">
            <Dialog.Panel className="relative flex w-full max-w-xs transform flex-col overflow-y-auto bg-white pb-12 shadow-xl transition duration-300 ease-in-out data-[closed]:-translate-x-full">
              <div className="flex px-4 pb-2 pt-5">
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon aria-hidden="true" className="size-6" />
                </button>
              </div>

              <div className="space-y-6 border-b border-gray-200 px-4 py-6">
                <a
                  href="tel:+443330155117"
                  className="block w-full md:w-max rounded-md bg-brand-600 px-6 py-4 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 outline outline-2 outline-offset-2 outline-brand-600 gradient-border transition-colors duration-200 hover:outline-brand-500 text-center"
                >
                  <span className="relative z-[1] text-center">
                    Call Us: 0333 015 5117
                  </span>
                </a>
                <Link
                  href="/request-a-callback"
                  className="block w-full md:w-max group bg-white rounded-md text-sm font-semibold px-6 py-4 leading-6 eading-6 text-gray-600 ring-2 ring-gray-900/10 hover:ring-brand-600 shadow-lg  transition-all duration-200 hover:shadow-lg hover:shadow-brand-200 text-center"
                >
                  Speak With An Advisor{" "}
                  <span
                    aria-hidden="true"
                    className="group-hover:pl-1 transition-all duration-150"
                  >
                    →
                  </span>
                </Link>
              </div>

              {/* Links */}
              <Tab.Group as="div" className="mt-2">
                <div className="border-b border-gray-200">
                  <Tab.List className="-mb-px flex space-x-8 px-4">
                    {navigation.categories.map((category) => (
                      <Tab
                        key={category.name}
                        className="flex-1 whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-base font-medium text-gray-900 data-[selected]:border-indigo-600 data-[selected]:text-indigo-600"
                      >
                        {category.link ? (
                          <a href={category.link}>{category.name}</a>
                        ) : (
                          <span>{category.name}</span>
                        )}
                      </Tab>
                    ))}
                  </Tab.List>
                </div>
                <Tab.Panels as={Fragment}>
                  {navigation.categories.map((category) => (
                    <Tab.Panel
                      key={category.name}
                      className="space-y-10 px-4 pb-8 pt-10"
                    >
                      <div className="space-y-4">
                        {category.featured.map((item, itemIdx) => (
                          <div
                            key={itemIdx}
                            className="group relative overflow-hidden rounded-md bg-gray-100"
                          >
                            <img
                              alt={item.imageAlt}
                              src={item.imageSrc}
                              className="aspect-square w-full object-cover group-hover:opacity-75"
                            />
                            <div className="absolute inset-0 flex flex-col justify-end">
                              <div className="bg-white/60 p-4 text-base sm:text-sm">
                                <a
                                  href={item.href}
                                  className="font-semibold text-gray-900"
                                >
                                  <span
                                    aria-hidden="true"
                                    className="absolute inset-0"
                                  />
                                  {item.name}
                                </a>
                                <p
                                  aria-hidden="true"
                                  className="mt-0.5 text-gray-700 sm:mt-1"
                                >
                                  Learn more
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      {category.sections.map((column, columnIdx) => (
                        <div key={columnIdx} className="space-y-4 md:space-y-2 grid grid-rows-2">
                          {column.map((section) => (
                            <div key={section.name}>
                              <a
                                href={section.link}
                                id={`${category.id}-${section.id}-heading-mobile`}
                                className="font-medium text-gray-900"
                              >
                                {section.name}
                              </a>
                              <ul
                                role="list"
                                aria-labelledby={`${category.id}-${section.id}-heading-mobile`}
                                className="mt-6 flex flex-col space-y-6"
                              >
                                {section.items.map((item) => (
                                  <li key={item.name} className="flow-root">
                                    <a
                                      href={item.href}
                                      className="-m-2 block p-2 text-gray-500"
                                    >
                                      {item.name}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))}
                        </div>
                      ))}
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>

              <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                {navigation.pages.map((page) => (
                  <div key={page.name} className="flow-root">
                    <a
                      href={page.href}
                      className="-m-2 block p-2 font-medium text-gray-900"
                    >
                      {page.name}
                    </a>
                  </div>
                ))}

                {navigation.mobile.map((y) => {
                  return (
                    <div className="flow-root" key={y.href}>
                      <a
                        href={y.href}
                        className="-m-2 block p-2 font-medium text-gray-900"
                      >
                        {y.name}
                      </a>
                    </div>
                  );
                })}
              </div>

              <div className="border-t border-gray-200 px-4 py-6"></div>
            </Dialog.Panel>
          </div>
        </Dialog>

        <header className="relative bg-white">
          <nav
            aria-label="Top"
            className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
          >
            <div className="border-b border-gray-200">
              <div className="flex h-16 items-center justify-between">
                <div className="flex flex-1 items-center lg:hidden">
                  <button
                    type="button"
                    onClick={() => setOpen(true)}
                    className="-ml-2 rounded-md bg-white p-2 text-gray-400"
                  >
                    <span className="sr-only">Open menu</span>
                    <Bars3Icon aria-hidden="true" className="size-6" />
                  </button>

                  <button
                    type="button"
                    onClick={() => setSearch(true)}
                    className="ml-2 p-2 text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">Search</span>
                    <MagnifyingGlassIcon
                      aria-hidden="true"
                      className="size-6"
                    />
                  </button>
                </div>

                {/* Flyout menus */}
                <Popover.Group className="hidden lg:block lg:flex-1 lg:self-stretch">
                  <div className="flex h-full space-x-8">
                    {navigation.categories.map((category) => (
                      <Popover key={category.name} className="flex group">
                        <div className="relative flex">
                          <Popover.Button className="group relative z-10 flex items-center justify-center text-sm font-medium text-gray-700 transition-colors duration-200 ease-out hover:text-gray-800 data-[headlessui-state=open]:text-brand-600">
                            {category.name}
                            <span
                              aria-hidden="true"
                              className="absolute inset-x-0 bottom-0 h-0.5 transition-colors duration-200 ease-out group-data-[headlessui-state=open]:bg-brand-600 sm:mt-5 sm:translate-y-px sm:transform"
                            />
                          </Popover.Button>
                        </div>

                        <Popover.Panel className="absolute inset-x-0 top-full transition data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in">
                          {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                          <div
                            aria-hidden="true"
                            className="absolute inset-0 top-1/2 bg-white shadow"
                          />

                          <div className="relative bg-white">
                            <div className="mx-auto max-w-7xl px-8">
                              <div className="grid grid-cols-2 gap-x-8 gap-y-10 py-16">
                                <div className="grid grid-cols-2 grid-rows-1 gap-8 text-sm">
                                  {category.featured.map((item, itemIdx) => (
                                    <div
                                      key={item.name}
                                      className={classNames(
                                        itemIdx === 0 ? "col-span-2" : "",
                                        "group relative overflow-hidden rounded-md bg-gray-100"
                                      )}
                                    >
                                      <img
                                        alt={item.imageAlt}
                                        src={item.imageSrc}
                                        className={classNames(
                                          itemIdx === 0
                                            ? "aspect-[2/1] min-h-[300px]"
                                            : "aspect-square min-h-[200px]",
                                          "w-full object-cover"
                                        )}
                                      />
                                      <div className="absolute left-0 right-0 bottom-0 flex flex-col justify-end">
                                        <div className="bg-white/60 p-4 text-sm">
                                          <a
                                            href={item.href}
                                            className="font-semibold text-gray-900"
                                          >
                                            <span
                                              aria-hidden="true"
                                              className="absolute inset-0"
                                            />
                                            {item.name}
                                          </a>
                                          <p
                                            aria-hidden="true"
                                            className="mt-0.5 text-gray-700 sm:mt-1"
                                          >
                                            Learn more
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                <div className="grid grid-cols-3 gap-x-8 gap-y-10 text-sm text-gray-500">
                                  {category.sections.map(
                                    (column, columnIdx) => (
                                      <div
                                        key={columnIdx}
                                        className="space-y-4 md:space-y-2 grid grid-rows-2"
                                      >
                                        {column.map((section) => (
                                          <div key={section.name}>
                                            <p
                                              id={`${category.id}-${section.id}-heading`}
                                              className="font-semibold text-gray-900"
                                            >
                                              <a
                                                href={section.link}
                                                className="hover:text-gray-800"
                                              >
                                                {section.name}
                                              </a>
                                            </p>
                                            <ul
                                              role="list"
                                              aria-labelledby={`${category.id}-${section.id}-heading`}
                                              className="mt-4 space-y-4"
                                            >
                                              {section.items.map((item) => (
                                                <li
                                                  key={item.name}
                                                  className="flex"
                                                >
                                                  <a
                                                    href={item.href}
                                                    className="hover:text-gray-800"
                                                  >
                                                    {item.name}
                                                  </a>
                                                </li>
                                              ))}
                                            </ul>
                                          </div>
                                        ))}
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Popover>
                    ))}

                    {navigation.pages.map((page) => (
                      <a
                        key={page.name}
                        href={page.href}
                        className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800"
                      >
                        {page.name}
                      </a>
                    ))}

                    <Popover key={navigation.more.name} className="flex group">
                      <div className="relative flex">
                        <Popover.Button className="group relative z-10 flex items-center justify-center text-sm font-medium text-gray-700 transition-colors duration-200 ease-out hover:text-gray-800 data-[headlessui-state=open]:text-brand-600">
                          {navigation.more.name}
                          <span
                            aria-hidden="true"
                            className="absolute inset-x-0 bottom-0 h-0.5 transition-colors duration-200 ease-out group-data-[headlessui-state=open]:bg-brand-600 sm:mt-5 sm:translate-y-px sm:transform"
                          />
                        </Popover.Button>
                      </div>

                      <Popover.Panel className="absolute inset-x-0 top-full transition data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in">
                        {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                        <div
                          aria-hidden="true"
                          className="absolute inset-0 top-1/2 bg-white shadow"
                        />

                        <div className="relative bg-white">
                          <div className="mx-auto max-w-7xl px-8">
                            <div className="grid grid-cols-2 gap-x-8 gap-y-10 py-16">
                              <div className="grid grid-cols-2 grid-rows-1 gap-8 text-sm">
                                {navigation.more.featured.map(
                                  (item, itemIdx) => (
                                    <div
                                      key={item.name}
                                      className={classNames(
                                        itemIdx === 0 ? "col-span-2" : "",
                                        "group relative overflow-hidden rounded-md bg-gray-100"
                                      )}
                                    >
                                      <img
                                        alt={item.imageAlt}
                                        src={item.imageSrc}
                                        className={classNames(
                                          itemIdx === 0
                                            ? "aspect-[2/1] min-h-[300px]"
                                            : "aspect-square min-h-[200px]",
                                          "w-full object-cover"
                                        )}
                                      />
                                      <div className="absolute inset-0 flex flex-col justify-end">
                                        <div className="bg-white/60 p-4 text-sm">
                                          <a
                                            href={item.href}
                                            className="font-semibold text-gray-900"
                                          >
                                            <span
                                              aria-hidden="true"
                                              className="absolute inset-0"
                                            />
                                            {item.name}
                                          </a>
                                          <p
                                            aria-hidden="true"
                                            className="mt-0.5 text-gray-700 sm:mt-1"
                                          >
                                            Learn more
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                              <div className="grid grid-cols-3 gap-x-8 gap-y-10 text-sm text-gray-500">
                                {navigation.more.sections.map(
                                  (column, columnIdx) => (
                                    <div key={columnIdx} className="space-y-10">
                                      {column.map((section) => (
                                        <div key={section.name}>
                                          <p
                                            id={`more-${section.id}-${section.id}-heading`}
                                            className="font-semibold text-gray-900"
                                          >
                                            <a
                                              href={section.link}
                                              className="hover:text-gray-800"
                                            >
                                              {section.name}
                                            </a>
                                          </p>
                                          <ul
                                            role="list"
                                            aria-labelledby={`more-${section.id}-${section.id}-heading`}
                                            className="mt-4 space-y-4"
                                          >
                                            {section.items.map((item) => (
                                              <li
                                                key={item.name}
                                                className="flex"
                                              >
                                                <a
                                                  href={item.href}
                                                  className="hover:text-gray-800"
                                                >
                                                  {item.name}
                                                </a>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      ))}
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Popover>
                  </div>
                </Popover.Group>

                {/* Logo */}
                <a href="/" className="flex">
                  <span className="sr-only">Cosmetic College</span>
                  <Image
                    src="/logo/cc-logo.png"
                    alt="The Cosmetic College"
                    width={135}
                    height={25}
                    className="hidden md:block"
                  />
                  <Image
                    src="/logo/icon.png"
                    alt="The Cosmetic College"
                    width={34}
                    height={34}
                    className="block md:hidden"
                  />
                </a>

                <div className="flex flex-1 items-center justify-end">
                  {/* Search */}
                  <a
                    // onClick={() => setSearch(!search)}
                    href="tel:+443330155117"
                    className="ml-6 p-2 text-gray-400 hover:text-gray-500 flex items-center"
                  >
                    <span className="sr-only">Call Us</span>
                    <PhoneIcon aria-hidden="true" className="size-5 mr-1" />
                    <span className="hidden md:block">0333 015 5117</span>
                  </a>

                  {/* Search */}
                  <button
                    type="button"
                    onClick={() => setSearch(!search)}
                    className="ml-6 hidden p-2 text-gray-400 hover:text-gray-500 lg:block"
                  >
                    <span className="sr-only">Search</span>
                    <MagnifyingGlassIcon
                      aria-hidden="true"
                      className="size-6"
                    />
                  </button>

                  {/* Account */}
                  <a
                    href="/account"
                    className="p-2 text-gray-400 hover:text-gray-500 lg:ml-4"
                  >
                    <span className="sr-only">Account</span>
                    <UserIcon aria-hidden="true" className="size-6" />
                  </a>

                  {/* Cart */}
                  <div className="ml-4 flow-root lg:ml-6">
                    {session.selectedCourses &&
                    session.selectedCourses.length > 0 ? (
                      <Link href="/checkout/" className="flex mr-2">
                        <div className="relative h-8 w-8 rounded-full text-brand-600 flex justify-center items-center hover:text-gray-200">
                          {session.selectedCourses &&
                            session.selectedCourses.length && (
                              <div className="absolute -top-1 -left-1 w-4 h-4 bg-blue-500 text-white rounded-full flex items-center justify-center text-[10px] font-semibold">
                                {session.selectedCourses.length}
                              </div>
                            )}
                          <ShoppingBagIcon
                            aria-hidden="true"
                            className="size-6 shrink-0 text-gray-400 group-hover:text-gray-500"
                          />
                        </div>
                      </Link>
                    ) : session.onlineCourses.length > 0 ? (
                      <button
                        type="button"
                        onClick={() =>
                          session.setContext((s) => ({
                            ...s,
                            onlineBasket: !s.onlineBasket,
                          }))
                        }
                        className="flex mr-2"
                      >
                        <div className="relative h-8 w-8 rounded-full text-brand-600 flex justify-center items-center hover:text-gray-200">
                          {session.onlineCourses &&
                            session.onlineCourses.length && (
                              <div className="absolute -top-1 -left-1 w-4 h-4 bg-blue-500 text-white rounded-full flex items-center justify-center text-[10px] font-semibold">
                                {session.onlineCourses.length}
                              </div>
                            )}
                          <ShoppingBagIcon
                            aria-hidden="true"
                            className="size-6 shrink-0 text-gray-400 group-hover:text-gray-500"
                          />
                        </div>
                      </button>
                    ) : (
                      <Link href="/checkout/" className="flex mr-2">
                        <div className="relative h-8 w-8 rounded-full text-brand-600 flex justify-center items-center hover:text-gray-200">
                          {session.selectedCourses &&
                            session.selectedCourses.length && (
                              <div className="absolute -top-1 -left-1 w-4 h-4 bg-blue-500 text-white rounded-full flex items-center justify-center text-[10px] font-semibold">
                                {session.selectedCourses.length}
                              </div>
                            )}
                          <ShoppingBagIcon
                            aria-hidden="true"
                            className="size-6 shrink-0 text-gray-400 group-hover:text-gray-500"
                          />
                        </div>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div>
      <HeaderSearch show={search} close={() => setSearch(false)} />
      <div className="sr-only">
        {siteData.fields.menu.links.map((x) => {
          return (
            <>
              <p key={x.link}>
                <a href={x.link}>{x.text}</a>
              </p>
              {x.children
                ? x.children.map((y) => {
                    return (
                      <p key={y.link}>
                        <a href={y.link}>{y.text}</a>
                        {y.description ? <span>{y.description}</span> : null}
                      </p>
                    );
                  })
                : null}
            </>
          );
        })}
      </div>
      <OnlineBookingCheckout
        {...rest}
        open={session.onlineBasket}
        setOpen={(s) => {
          session.setContext({ ...session, onlineBasket: s });
        }}
      />
    </>
  );
}
