import { format, formatDistanceToNow, isToday, isYesterday } from "date-fns";

export function currency(amount, currency = "GBP", locale = "en-GB") {
  if (amount === null || amount === undefined) return null;
  return new Intl.NumberFormat(locale, { style: "currency", currency }).format(
    amount / 100
  );
}

export const timeAgo = (date: Date): string => {
  if (isToday(date)) {
    return formatDistanceToNow(date, { addSuffix: true });
  } else if (isYesterday(date)) {
    return `Yesterday at ${format(date, "HH:mm")}`;
  } else {
    return format(date, "do MMMM yyyy HH:mm");
  }
};
