import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import * as Sentry from "@sentry/nextjs";
import { Fragment, useState } from "react";

import { useSession } from "../providers/session";

import { currency } from "utils/formats";
import Spinner from "../assets/spinner.svg";
import { PaymentMethods } from "./methods";
import OnlineCourse, { Entry } from "./online-course";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export default function OnlineBookingCheckout({
  open,
  setOpen,
  ...rest
}: Props) {
  const { onlineCourses } = useSession();

  const [state, setState] = useState<{
    loading: boolean;
    error: any;
    additional: any[];
  }>({
    loading: false,
    error: null,
    additional: [],
  });

  console.log("rest", rest);
  const total = calculateTotal(onlineCourses, state.additional);

  const priceIds = onlineCourses.map((course) => course.fields.pricing.id);

  const handleSubmit = async () => {
    setState((s) => ({ ...s, loading: true }));

    try {
      gtag("event", "begin_checkout", {
        event_callback: function () {},
      });
    } catch (err) {
      Sentry.captureException(err);
      console.log(err);
    }

    try {
      const req = await fetch("/api/e-learning/create-checkout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          priceIds,
          additional:
            state.additional.length > 0
              ? state.additional.map((x) => Mapping[x].price_data)
              : [],
        }),
      });
      const res = await req.json();
      console.log(res);
      window.location.href = res.url;
    } catch (err) {
      setState((s) => ({ ...s, loading: false }));
      console.log(err);
      Sentry.captureException(err);
      setState((s) => ({ ...s, loading: false }));
    }
  };

  console.log("onlineCourses", onlineCourses);
  console.log("priceIds", priceIds);
  console.log("state", state);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <section
                      className="min-h-screen mx-auto fixed right-0 top-0 bottom-0 z-50 bg-white"
                      id="booking"
                    >
                      <h1 className="sr-only">Checkout</h1>
                      <div className="flex flex-col h-[100vh]  overflow-hidden">
                        <section
                          id="order-summary"
                          className="flex-1 px-4 pt-4 w-full h-full flex flex-col lg:px-8 pb-4 mx-auto border shadow"
                        >
                          <div className="flex justify-between py-2">
                            <h2 className="text-2xl mb-2 font-medium">
                              Order Summary
                            </h2>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                          <div className="flex flex-col xl:max-w-4xl flex-1 overflow-scroll pt-4">
                            <div className="flow-root flex-1">
                              {(!onlineCourses ||
                                (onlineCourses &&
                                  onlineCourses.length === 0)) && (
                                <div className="flex flex-col space-y-4">
                                  <p>
                                    You have not added any online courses to
                                    your basket
                                  </p>
                                  <div className="">
                                    <button
                                      type="button"
                                      className="btn-secondary"
                                      onClick={() => setOpen(false)}
                                    >
                                      Continue browsing
                                    </button>
                                  </div>
                                </div>
                              )}
                              {onlineCourses && (
                                <>
                                  <ul
                                    role="list"
                                    className="-my-6 divide-y divide-gray-200"
                                  >
                                    {onlineCourses.map((onlineCourse, i) => {
                                      return (
                                        <li key={`service-${i}`}>
                                          <OnlineCourse
                                            onlineCourse={onlineCourse}
                                            itemIndex={i}
                                          />
                                          {Mapping[
                                            onlineCourse.fields.pricing.id
                                          ] && (
                                            <div className="bg-brand-500 text-sm p-2 rounded-md text-white">
                                              <h3 className="font-semibold">
                                                {
                                                  Mapping[
                                                    onlineCourse.fields.pricing
                                                      .id
                                                  ].title
                                                }
                                              </h3>
                                              <p className="text-xs">
                                                {
                                                  Mapping[
                                                    onlineCourse.fields.pricing
                                                      .id
                                                  ].description
                                                }
                                              </p>
                                              <div className="flex items-end justify-end">
                                                <button
                                                  type="button"
                                                  className="text-xs text-center border p-2 mt-2 bg-gray-100 border-gray-200 text-gray-600 rounded-md"
                                                  onClick={() => {
                                                    state.additional.includes(
                                                      onlineCourse.fields
                                                        .pricing.id
                                                    )
                                                      ? setState((s) => ({
                                                          ...s,
                                                          additional:
                                                            s.additional.filter(
                                                              (id) =>
                                                                id !==
                                                                onlineCourse
                                                                  .fields
                                                                  .pricing.id
                                                            ),
                                                        }))
                                                      : setState((s) => ({
                                                          ...s,
                                                          additional: [
                                                            ...s.additional,
                                                            onlineCourse.fields
                                                              .pricing.id,
                                                          ],
                                                        }));
                                                  }}
                                                >
                                                  {state.additional.includes(
                                                    onlineCourse.fields.pricing
                                                      .id
                                                  )
                                                    ? "Remove"
                                                    : Mapping[
                                                        onlineCourse.fields
                                                          .pricing.id
                                                      ].cta}
                                                </button>
                                              </div>
                                            </div>
                                          )}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="">
                            {onlineCourses && onlineCourses.length > 0 && (
                              <>
                                {/* <Voucher items={items} /> */}
                                <dl className="text-sm font-medium text-gray-500">
                                  <div className="flex flex-col  text-gray-900 pt-4">
                                    <dt className="text-base flex justify-between">
                                      <span>Total</span>
                                      <span>{currency(total)}</span>
                                    </dt>
                                  </div>
                                </dl>
                              </>
                            )}
                            {onlineCourses.length > 0 ? (
                              <div className="flex flex-col justify-between mt-2 pb-12 sm:pb-0">
                                <button
                                  disabled={state.loading}
                                  type="button"
                                  onClick={() => handleSubmit()}
                                  className="cursor-pointer block w-full rounded-md bg-brand-600 px-6 py-4 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 outline outline-2 outline-offset-2 outline-brand-600 gradient-border transition-colors duration-200 hover:outline-brand-500 text-center"
                                >
                                  {!state.loading && (
                                    <span className="relative z-[1] text-center">
                                      Book Now
                                    </span>
                                  )}
                                  {state.loading && (
                                    <span className="relative z-[1] text-center">
                                      Processing
                                    </span>
                                  )}
                                  {state.loading && (
                                    <span className="absolute right-1 z-[1]">
                                      <Spinner className="w-9 h-9" />
                                    </span>
                                  )}
                                </button>
                              </div>
                            ) : null}
                            <PaymentMethods />
                          </div>
                        </section>
                      </div>
                    </section>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

function calculateTotal(courses: Entry[], additional: any[]): number {
  let amt = 0;
  if (courses && courses.length > 0) {
    amt = courses?.reduce((total, course) => {
      const amount = course.fields.pricing?.unit_amount || 0;
      return total + amount;
    }, 0);
  }

  if (additional && additional.length > 0) {
    amt = additional.reduce((total, id) => {
      const amount = Mapping[id].price_data.unit_amount || 0;
      return total + amount;
    }, amt);
  }

  return amt;
}

const Mapping = {
  // Skin Peels
  price_1Lbr8mAlQ13ioQgbMBxECkNT: {
    title: "Add Microneedling and save!",
    description:
      "Take your skills further with Microneedling at a special price",
    cta: "Add Microneedling for £39.99",
    // Microneedling
    price_data: {
      currency: "gbp",
      product: "prod_MJLQL3u7AtJZp1",
      unit_amount: 3999,
    },
  },
  // Dermaplaning
  price_1QixN1AlQ13ioQgbJmX9eXe6: {
    title: "Add LED Light Therapy and save!",
    description:
      "Take your skills further with LED Light Therapy at a special price",
    cta: "Add for £19.99",
    // Microneedling
    price_data: {
      currency: "gbp",
      product: "prod_MJLL8EcE2uGTcC",
      unit_amount: 1999,
    },
  },
  // Brow Lamination
  price_1QixQ5AlQ13ioQgbYRnBXab1: {
    title: "Add Lash Lift & Tint and save!",
    description:
      "Take your skills further with Lash Lift & Tint at a special price",
    cta: "Add for £19.99",
    // Microneedling
    price_data: {
      currency: "gbp",
      product: "prod_MJLP6cWMIiBsMY",
      unit_amount: 1999,
    },
  },
  // Microneedling
  price_1Laik3AlQ13ioQgbJZiIMI2k: {
    title: "Add BB Glow and save!",
    description: "Take your skills further with BB Glow at a special price",
    cta: "Add for £29.99",
    // Microneedling
    price_data: {
      currency: "gbp",
      product: "prod_MJODjPh2SyogUg",
      unit_amount: 2999,
    },
  },
  // Aqualyx
  price_1QixTIAlQ13ioQgbIGdifHZl: {
    title: "Add Lemon Bottle and save!",
    description:
      "Take your skills further with Lemon Bottle at a special price",
    cta: "Add for £39.99",
    // Microneedling
    price_data: {
      currency: "gbp",
      product: "prod_PjfjyDKzHYiZnc",
      unit_amount: 3999,
    },
  },
  // AP
  price_1MwXgkAlQ13ioQgbnoFrgGcS: {
    title: "Add Level 4 Laser and save!",
    description: "Take your skills further with Laser at a special price",
    cta: "Add for £399.99",
    // Microneedling
    price_data: {
      currency: "gbp",
      product: "prod_QRUvvf6zpds4hM",
      unit_amount: 39999,
    },
  },
  // PDO Mono
  price_1QizkvAlQ13ioQgbnWsSDSV7: {
    title: "Add PDO Cog Threads and save!",
    description:
      "Take your skills further with PDO Cog Threads at a special price",
    cta: "Add for £69.99",
    // PDO Cogs
    price_data: {
      currency: "gbp",
      product: "prod_RIreWj0v907o8K",
      unit_amount: 6999,
    },
  },
  // PDO Cogs
  price_1Qizk3AlQ13ioQgbPv3FP7Kx: {
    title: "Add PDO Mono Threads and save!",
    description:
      "Take your skills further with PDO Mono Threads at a special price",
    cta: "Add for £69.99",
    // PDO Mono
    price_data: {
      currency: "gbp",
      product: "prod_RIrdfAaZGrb3jo",
      unit_amount: 6999,
    },
  },
  // Mesotherapy
  price_1Qizm5AlQ13ioQgb8zt8uRqO: {
    title: "Add Mesotoxin and save!",
    description: "Take your skills further with Mesotoxin at a special price",
    cta: "Add for £69.99",
    // mesotoxin
    price_data: {
      currency: "gbp",
      product: "prod_RIrZqhSCXWMukU",
      unit_amount: 6999,
    },
  },
  price_1R46xLAlQ13ioQgbCutteHi7: {
    title: "Add Profhilo Face & Body and save!",
    description: "Take your skills further with Profhilo Face & Body at a special price",
    cta: "Add for £79.99",
    // mesotoxin
    price_data: {
      currency: "gbp",
      product: "prod_MeLKcoA5jRWi2X",
      unit_amount: 7999,
    },
  },
};
